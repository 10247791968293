<template lang="pug">
  .home.fb
    .fb__container.container

      //- Титульная линия страницы
      .fb__top-line
        .fb__title-container.fb__title-container--row
          h1.fb__title {{ title }}
          el-tooltip(
            content="Дополнительная информация"
            placement="right"
            effect="light"
          )
            button.fb__tooltip.btn.btn--tooltip
              svg(width="16" height="16")
                use(xlink:href="@/assets/images/sprite/sprite.svg#icon-info")

</template>

<script>

export default {
  quantity: 'Main',

  data() {
    return {
      title: 'Объекты',
    }
  },
}
</script>
